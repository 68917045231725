<template>
  <div :id="id" class="select_wrap">
    <div class="select" @click="open = !open" :class="{ active: open }">
      <div class="label">
        {{ this.options[this.watch - 2].title }}
      </div>
      <button type="button"></button>
    </div>
    <div class="select_items" v-show="open">
      <ul>
        <li
          v-for="(option, index) in options"
          :key="option.name"
          :class="index == watch - 2 ? 'selected' : ''"
          @click="changeIndex(index)"
        >
          <a>{{ option.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    watch: {
      required: true,
    },
    options: {
      required: true,
    },
    commit: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    changeIndex(index) {
      index = index + 2;

      if (index != undefined) {
        this.$store.commit(this.commit, index);
      } else {
        this.$store.commit(this.commit, 0);
      }
      this.open = false;
    },
  },
  mounted() {
    document.addEventListener(
      'click',
      function(event) {
        // If the click inside the element, do nothing
        if (
          event.target.closest(`#${this.id}`) &&
          !event.target.closest('.disabled')
        )
          return;
        // If the clicks outside the element, hide it!
        this.open = false;
      }.bind(this),
    );
  },
};
</script>

<style></style>
